import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkSessionStatus, doLogout } from '../redux/AuthActions';
import { callStatus } from '../redux/constants';
import { doSetUsage, sessionExpired } from '../redux/SessionActions';
import store from '../redux/store';

const useForceLogout = () => {
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const session = useSelector(({ session }) => session);
  const translations = useSelector(({ translations }) => translations);
  const context = useSelector(({ context }) => context);

  let intervalCheck = useRef();
  let intervalUsage = useRef();
  let modalOpen = useRef(false);
  let forceLogout = useRef(false);
  let forceLogoutInterval = useRef(null);
  let forceLogoutIntervalRun = useRef(false);
  let hasEventListener = useRef(false);

  const [slMainForceLogout, setSlMainForceLogout] = useState(null);

  useEffect(() => {
    if (context?.configVars?.SL_MAIN_FORCE_LOGOUT) {
      setSlMainForceLogout(context.configVars.SL_MAIN_FORCE_LOGOUT);
    }
  }, [context]);

  useEffect(() => {
    if (session.status === callStatus.SUCCESS) {
      const startIntervalCheck = () => {
        intervalCheck.current = setInterval(async () => {
          const res = await checkSessionStatus();
          const dataAuth = store.getState().auth;
          const dataSession = store.getState().auth;
          if (
            dataAuth.status === callStatus.SUCCESS &&
            dataSession.status === callStatus.SUCCESS &&
            res?.data?.user &&
            !isEqual(res.data.user, dataAuth.data['new_user']) &&
            !isEqual(res.data.user, dataAuth.data['user'])
          ) {
            modalOpen.current = true;
            const messageContent = res.data.is_authenticated
              ? translations.data.login.another_user_logged_in
              : translations.data.login.user_logged_out;

            dispatch(sessionExpired(messageContent));
          }
        }, context?.configVars?.SL_MAIN_CHECK_SESSION_STATUS_TIME || 300000);
      };
      !intervalCheck.current && startIntervalCheck();

      const startIntervalUsage = () => {
        intervalUsage.current = setInterval(async () => {
          dispatch(doSetUsage());
        }, context?.configVars?.SL_MAIN_UPDATE_USAGE_TIME || 150000);
      };
      !intervalUsage.current && startIntervalUsage();
    }

    if (
      session.status === callStatus.PENDING ||
      session.status === callStatus.EXPIRED ||
      session.status === callStatus.FAILED_GET
    ) {
      intervalCheck.current && clearInterval(intervalCheck.current);
      intervalCheck.current = null;
      intervalUsage.current && clearInterval(intervalUsage.current);
      intervalUsage.current = null;
    }

    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (
      auth.status === callStatus.SUCCESS &&
      session.status === callStatus.SUCCESS
    ) {
      forceLogoutInterval.current = setInterval(() => {
        const forceLogoutTimestamp = localStorage.getItem(
          'force_logout_timestamp'
        );

        if (
          forceLogoutIntervalRun.current === false &&
          slMainForceLogout &&
          forceLogoutTimestamp
        ) {
          forceLogoutIntervalRun.current = true;

          if (forceLogoutTimestamp) {
            const timeDiff = forceLogoutTimestamp
              ? Math.abs(Date.now() - forceLogoutTimestamp) / 1000
              : undefined;

            if (timeDiff && timeDiff > slMainForceLogout) {
              localStorage.removeItem('force_logout_timestamp');

              dispatch(doLogout());
              forceLogout.current = false;
            }
          }

          clearInterval(forceLogoutInterval.current);
        } else if (!forceLogoutTimestamp) {
          clearInterval(forceLogoutInterval.current);
        }
      }, 500);
    }

    return () => {
      if (forceLogoutInterval.current) {
        clearInterval(forceLogoutInterval.current);
      }
    };
  }, [dispatch, auth.status, session.status, slMainForceLogout]);

  useEffect(() => {
    const handleTabClose = (event) => {
      if (forceLogout.current) {
        localStorage.setItem('force_logout_timestamp', Date.now());
      }
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hasEventListener.current) {
      document.addEventListener('visibilitychange', async () => {
        const dataAuth = store.getState().auth;

        if (!document.hidden && dataAuth.status === callStatus.SUCCESS) {
          hasEventListener.current = true;

          if (!modalOpen.current) {
            const res = await checkSessionStatus();
            if (
              res?.data?.user &&
              !isEqual(res.data.user, dataAuth.data['new_user']) &&
              !isEqual(res.data.user, dataAuth.data['user'])
            ) {
              modalOpen.current = true;
              const messageContent = res.data.is_authenticated
                ? translations.data.login.another_user_logged_in
                : translations.data.login.user_logged_out;

              dispatch(sessionExpired(messageContent));
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      if (
        !forceLogout.current &&
        context?.configVars?.SL_MAIN_FORCE_LOGOUT &&
        context?.configVars?.SL_MAIN_FORCE_LOGOUT > 0
      ) {
        forceLogout.current = true;
      }
    }
    // eslint-disable-next-line
  }, [auth.status, context?.configVars?.SL_MAIN_FORCE_LOGOUT, forceLogout]);

  return { modalOpen, forceLogout };
};

export default useForceLogout;
