import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../package.json';
import Login from './Login';
import Main from './components/Main';
import useForceLogout from './hooks/useForceLogout';
import { doGetLogin } from './redux/AuthActions';
import {
  doGetBackendVersion,
  doGetConfigVars,
  setConsoleOutput,
} from './redux/ContextAction';
import { doSetUsage } from './redux/SessionActions';
import { doGetTranslations } from './redux/TranslationAction';
import { doGetLoginTranslations } from './redux/TranslationLoginAction';
import commandDef from './redux/cli/commandsDef.json';
import { callStatus } from './redux/constants';
import { importCliProvider } from './utils/dynamic-imports-sl-cli';

const CliProvider = importCliProvider();

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const context = useSelector(({ context }) => context);
  const translations = useSelector(({ translations }) => translations);

  const { modalOpen, forceLogout } = useForceLogout();

  useEffect(() => {
    if (auth.status === callStatus.PENDING) {
      dispatch(doGetLogin());
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    doGetBackendVersion(dispatch);
    doGetLoginTranslations(dispatch);
    doGetConfigVars(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      doGetTranslations(dispatch);
      dispatch(doSetUsage(true));
    }
    // eslint-disable-next-line
  }, [auth.status, dispatch]);

  const getCommandDef = (command) => {
    return commandDef.commands.find(
      (commandDef) =>
        commandDef.name === command || commandDef.acronym === command
    );
  };

  const customParse = (command, dispatch = null) => {
    try {
      let commandDef = getCommandDef(command.name);

      if (!commandDef) {
        throw new Error(translations.data.cli.unrecognized_command);
      }

      let action = {};
      action.type = commandDef.name;
      action.params = {};

      const paramsText = Object.entries(command.params)
        .map(([key, value]) => {
          return isNaN(key) ? `${key}:${value}` : value;
        })
        .join(' ');
      action.commandText = `${command.name} ${paramsText}`;

      const params = command.params;

      commandDef.parameters.forEach((param, index) => {
        let value = params[param.name] || params[param.acronym];
        value = value ? value : params[index];

        if (value) {
          action.params[param.name] = value;
        } else if (param.mandatory) {
          throw new Error(
            `${translations.data.cli.parameter_required} (Parameter: ${param.name})`
          );
        }
      });

      return action;
    } catch (error) {
      dispatch
        ? dispatch(setConsoleOutput({ errors: error }))
        : console.log(error);

      throw error;
    }
  };

  if (
    auth.status === callStatus.PENDING ||
    auth.status === callStatus.FAILED ||
    auth.status === callStatus.FAILED_GET ||
    auth.status === callStatus.IN_PROCESS
  ) {
    return (
      <div style={{ height: '100vh' }}>
        <Login modalOpen={modalOpen} />
      </div>
    );
  }

  return (
    <CliProvider
      commands={commandDef}
      translations={translations.data.cli}
      version={{
        frontend: packageJson.version,
        backend: context.backendVersion,
      }}
      customParse={customParse}
    >
      <Main forceLogoutRef={forceLogout} modalOpen={modalOpen} />
    </CliProvider>
  );
};

export default App;
