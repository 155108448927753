import { authApi } from '../utils/api';
import { sessionPending } from './SessionActions';
import { CHANGE_PASSWORD, LOGIN } from './types/AuthActionTypes';

export const doLogin = (data, modalOpen) => (dispatch) => {
  dispatch(loginInProgress());
  return authApi
    .post(
      '/login/',
      {
        usr: data.username,
        pwd: data.password,
      },
      {
        maxRedirects: 0,
      }
    )
    .then((response) => {
      modalOpen.current = false;
      dispatch(loginSuccess(response.data));
    })
    .catch((error) => {
      dispatch(loginFailed(error));
    });
};

export const doGetLogin = () => (dispatch) => {
  dispatch(loginInProgress());
  return authApi
    .get('/check_session_status/')
    .then((response) => {
      if (response.data.is_authenticated) {
        dispatch(loginSuccess(response.data));
      } else {
        dispatch(loginFailedGet());
      }
    })
    .catch((error) => {
      dispatch(loginFailed(error));
    });
};

export const doLogout = () => (dispatch) => {
  dispatch(loginInProgress());
  return authApi
    .get('/logout/')
    .then(async (response) => {
      dispatch(loginFailedGet());
      dispatch(sessionPending());
    })
    .catch((error) => {
      dispatch(loginFailed(error));
    });
};

export const doChangePassword = (data) => (dispatch) => {
  return authApi
    .post('/change_password/', {
      usr: data.user,
      old_pwd: data.old_password,
      new_pwd: data.new_password,
    })
    .then((response) => {
      dispatch(loginPasswordResolved());
    })
    .catch((error) => {
      dispatch(loginPasswordRejected(error));
    });
};

export const checkSessionStatus = async () => {
  return authApi.get('/check_session_status/');
};

export const doExpired =
  (message = null) =>
  (dispatch) => {
    return authApi
      .get('/logout/')
      .then(async (response) => {
        dispatch(loginExpired(message));
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };

const loginInProgress = () => {
  return {
    type: LOGIN.IN_PROGRESS,
  };
};
const loginFailed = (error) => {
  return {
    type: LOGIN.REJECTED,
    payload: error && error.message,
  };
};
export const loginSuccess = (data) => {
  return {
    type: LOGIN.RESOLVED,
    payload: data,
  };
};
const loginFailedGet = () => {
  return {
    type: LOGIN.FAILED_GET,
  };
};
export const loginExpired = (data) => {
  return {
    type: LOGIN.EXPIRED,
    payload: data,
  };
};
export const loginRefresh = (data) => {
  return {
    type: LOGIN.REFRESH,
    payload: data,
  };
};

export const loginPasswordPending = () => {
  return {
    type: CHANGE_PASSWORD.IDLE,
  };
};
const loginPasswordResolved = () => {
  return {
    type: CHANGE_PASSWORD.RESOLVED,
  };
};
const loginPasswordRejected = (error) => {
  return {
    type: CHANGE_PASSWORD.REJECTED,
    payload: error.message,
  };
};
