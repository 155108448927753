import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { callStatus } from './constants';
import {
  TRANSLATION_FAILED,
  TRANSLATION_IN_PROCESS,
  TRANSLATION_PENDING,
  TRANSLATION_SUCCESS,
} from './types/TranslationActionTypes';

const dictionary = {
  cli: {
    command: 'cli#command',
    console: 'cli#console',
    json: 'cli#json',
    context: 'cli#context',
    valid_commands: 'cli#valid_commands',
    parameter_required: 'cli#parameter_required',
  },
  navegation: {
    change_password: 'navegation#change_password',
    about: 'navegation#about',
    logout: 'navegation#logout',
    refresh: 'navegation#refresh',
    duplicate: 'navegation#duplicate',
    new_tab: 'navegation#new_tab',
    workstation: 'navegation#workstation',
  },
  password: {
    title_modal: 'password#title_modal',
    old_password: 'password#old_password',
    new_password: 'password#new_password',
    confirm_password: 'password#confirm_password',
    close_button: 'password#close_button',
    change_button: 'password#change_button',
    message_old_password: 'password#message_old_password',
    message_new_password: 'password#message_new_password',
    message_confirm_new_password: 'password#message_confirm_new_password',
    message_equal_password: 'password#message_equal_password',
    message_change_success: 'password#message_change_success',
  },
  workstation: {
    wks: 'workstation#wks',
    title_modal: 'workstation#title_modal',
  },
  login: {
    username: 'login#username',
    password: 'login#password',
    login: 'login#login',
    forgot_password: 'login#forgot_password',
    logged_out: 'login#logget_out', // You has been logged out
    another_user_logged_in: 'login#anther_user_logged_in', // Another user has logged in. You has been redirected to the login page.
    user_logged_out: 'login#user_logged_out',
    username_required: 'login#username_required',
    password_required: 'login#password_required',
  },
};
const initialState = {
  data: cloneDeep(dictionary),
  errors: [],
  status: callStatus.PENDING,
};

const TranslationReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case TRANSLATION_PENDING:
      newState = {
        ...state,
        status: callStatus.PENDING,
        data: cloneDeep(dictionary),
        errors: [],
      };

      return newState;
    case TRANSLATION_IN_PROCESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
        errors: [],
      };

      return newState;
    case TRANSLATION_SUCCESS:
      newState = {
        ...state,
        data: merge(state.data, action.data),
        status: callStatus.SUCCESS,
        errors: [],
      };

      return newState;
    case TRANSLATION_FAILED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        errors: action.data,
      };

      return newState;

    default:
      return state;
  }
};

export default TranslationReducer;
